import react, { useCallback } from 'react';
import { Icon } from 'antd';

const IconList = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3632177_40mf8j4lrqq.js' // 在 iconfont.cn 上生成
});

// 自定义图标
const CustomIcon = ({ type, clickCallback, classnames, style }) => {
  // 点击回调
  const handleClickCallback = useCallback(() => {
    if (clickCallback) clickCallback();
  }, [clickCallback]);

  return (
    <IconList
      className={`${classnames}`}
      onClick={handleClickCallback}
      type={type}
      style={style}
    />
  );
};

export default CustomIcon;
