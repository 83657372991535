/**
 * We do not use localStorage to store token and userInfo, because in some broswers
 * localStorage is VERY NOT stable, especially FireFox. Check this Stack Overflow
 * question:
 * https://stackoverflow.com/questions/13852209/localstorage-unreliable-in-firefox
 *
 * We use follow cookies:
 * domain_bridge_token_admin
 * domain_bridge_userid_admin
 * domain_bridge_username_admin
 * domain_bridge_role0code_admin
 * domain_bridge_token_console
 * domain_bridge_userid_console
 * domain_bridge_username_console
 * domain_bridge_role0code_console
 *
 * @zhaoxuxu @2020-1-10
 */
import cookie from '../utils/cookie';

function whichSystem() {
  return 'console';
}

function getSecondLevelDomain() {
  if (document.domain.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)) {
    return document.domain;
  }
  return document.domain.split('.').slice(-2).join('.');
}

export default {
  setUserAuth(token, userInfo) {
    const commonCookieCfg =
      `max-age=${3600 * 24}; ` +
      `domain=${getSecondLevelDomain()}; ` +
      'path=/';
    document.cookie =
      `domain_bridge_token_${whichSystem()}=${token}; ` + commonCookieCfg;
    document.cookie =
      `domain_bridge_userid_${whichSystem()}=${userInfo.id}; ` +
      commonCookieCfg;
    document.cookie =
      `domain_bridge_username_${whichSystem()}=${userInfo.username}; ` +
      commonCookieCfg;
    document.cookie =
      `domain_bridge_role0code_${whichSystem()}=${userInfo.roles[0].code}; ` +
      commonCookieCfg;
  },
  setToken(token) {
    const commonCookieCfg =
      `max-age=${3600 * 24}; ` +
      `domain=${getSecondLevelDomain()}; ` +
      'path=/';
    document.cookie =
      `domain_bridge_token_${whichSystem()}=${token}; ` + commonCookieCfg;
  },
  getToken() {
    return cookie.get('domain_bridge_token_' + whichSystem());
  },
  getUserInfo() {
    const userid = cookie.get('domain_bridge_userid_' + whichSystem());
    const username = cookie.get('domain_bridge_username_' + whichSystem());
    const role0code = cookie.get('domain_bridge_role0code_' + whichSystem());
    return { userid, username, role0code };
  },
  clearUserAuth() {
    localStorage.removeItem('userId');
    const commonCookieCfg =
      'expires=Thu, 01 Jan 1970 00:00:00 GMT; ' +
      `domain=${getSecondLevelDomain()}; ` +
      'path=/';
    document.cookie =
      `domain_bridge_token_${whichSystem()}=; ` + commonCookieCfg;
    document.cookie =
      `domain_bridge_userid_${whichSystem()}=; ` + commonCookieCfg;
    document.cookie =
      `domain_bridge_username_${whichSystem()}=; ` + commonCookieCfg;
    document.cookie =
      `domain_bridge_role0code_${whichSystem()}=; ` + commonCookieCfg;
  },

  hasLoggedin() {
    const token = this.getToken();
    return !!token;
  }
};
