import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from 'react';
import { withRouter } from 'next/router';
import { Select, Icon, Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import less from './Navigator.less';
// import './antSelect.css';
import account from '@/common/account/index';
import whichEnvironment from '@/common/utils/whichEnvironment';
import CustomIcon from '@/components/EvaluationVideoComponents/CustomIcon';
import navigatorData from './navigator.json';
import { TAG_ZH_TO_EN } from '../../constants/navigator';
import initAbilityData from './navSearch';

const { Option } = Select;

// 二级标题映射的icon
const SECOND_TITLE_TO_ICON = {
  智能讲解: 'icon-fi-rs-e-learning',
  智能评测: 'icon-fi-rs-pulse',
  教育OCR: 'icon-fi-rs-inbox',
  智能批改: 'icon-fi-rs-edit',
  数据标注: 'icon-fi-rs-vector',
  语音交互: 'icon-fi-rs-microphone',
  文字识别: 'icon-fi-rs-expand',
  '图像/版面分析': 'icon-fi-rs-mode-landscape',
  AI云课堂: 'icon-fi-rs-graduation-cap',
  AI云题库: 'icon-fi-rs-layers',
  文档中心: 'icon-fi-rs-folder',
  基础平台: 'icon-fi-rs-chart-connected',
  数据中心: 'icon-fi-rs-vector'
};

const Navigator = (props) => {
  // 音视频直播pass链接
  const video_live_broadcast = useRef(
    process.env.NEXT_PUBLIC_VIEDO_LIVE_BROADCAST
  ).current;
  const gotoHomePage = useCallback(() => {
    window.location.href = '/';
  }, []);
  // 开放能力三级菜单跳转
  const gotoProductPage = useCallback((name) => {
    console.log('name', name);
    if (name.startsWith('/')) {
      window.location.href = name;
    } else {
      window.location.href = `/product/${name}`;
    }
  }, []);
  // 解决方案三级菜单跳转
  const gotoSolutionPage = useCallback((name) => {
    window.location.href = `/solution?name=${name}`;
  }, []);
  // 服务与支持三级跳转
  const gotoSevircePage = useCallback((name) => {
    if (name) {
      window.location.href = name;
    }
  }, []);
  // 服务与支持Pass跳转
  const gotoPass = useCallback(
    (item) => {
      // let cookie = document.cookie.split('; ');
      // let middleToken = cookie.find((value) => {
      //   return /^domain_bridge_token_console/.test(value);
      // });
      // let token = '';
      // if (middleToken) {
      //   token = middleToken.split('=')[1];
      // }
      const token = account.getToken();
      if (item.label == '音视频直播pass平台') {
        if (token) {
          window.open(`${video_live_broadcast}?userInfo=${token}`);
        } else {
          window.open(video_live_broadcast);
        }
      }
    },
    [video_live_broadcast]
  );
  const gotoInfoPage = useCallback((url = '/infos/infoDetails') => {
    window.location.href = url;
  }, []);

  // AI题库链接
  const ai_university_url = useRef(process.env.NEXT_PUBLIC_AI_UNIVERSITY);
  const ai_school_url = useRef(process.env.NEXT_PUBLIC_AI_SCHOOL);
  const paasUrl = useRef('');

  // AI题库: 测试环境域名不是.100tal.com，导致cookie跨域，所以测试环境使用传参数token的方式
  const gotoUniversity = useCallback((url) => {
    const { NEXT_PUBLIC_NODE_ENV } = process.env;
    const token = account.getToken();
    let newUrl = url;
    if (['development', 'beta'].includes(NEXT_PUBLIC_NODE_ENV) && token) {
      newUrl = `${url}&token=${token}`;
    }
    window.open(newUrl);
  }, []);

  // AI云课堂: 测试环境增加userTag=ai参数，正式环境不需要
  const gotoSchool = useCallback((url) => {
    const { NEXT_PUBLIC_NODE_ENV } = process.env;
    const token = account.getToken();
    let newUrl = url;
    let userTag = '';
    let userInfo = '';
    if (['development', 'beta'].includes(NEXT_PUBLIC_NODE_ENV)) {
      userTag = '&userTag=ai';
    }
    if (token) {
      userInfo = `&userInfo=${token}`;
    }
    newUrl = `${url}${userTag}${userInfo}`;
    window.open(newUrl);
  }, []);

  const gotoAISchool = useCallback(
    (key, url) => {
      if (key === 'AI题库') {
        gotoUniversity(ai_university_url.current + url);
      } else {
        gotoSchool(ai_school_url.current + url);
      }
    },
    [gotoSchool, gotoUniversity]
  );

  // 开发与生态服务
  const gotoDevAndService = useCallback(
    (name, key) => {
      if (key === 'AI云课堂') {
        gotoSchool(ai_school_url.current + name);
      } else if (key === 'AI云题库') {
        gotoUniversity(ai_university_url.current + name);
      } else if (name.startsWith('/')) {
        window.location.href = name;
      } else {
        window.location.href = '/';
      }
    },
    [gotoSchool, gotoUniversity]
  );

  useEffect(() => {
    switch (whichEnvironment()) {
      case 'CODING':
        paasUrl.current = 'http://localhost:8080';
        break;
      case 'DEV':
        paasUrl.current = 'https://gateway-test-multi.facethink.com';
        break;
      case 'TEST':
        paasUrl.current = 'https://gateway-test-multi.facethink.com';
        break;
      case 'RELEASE':
        paasUrl.current = 'https://openai.100tal.com';
        break;
      default:
        break;
    }
  }, []);

  const { isNavigatorAlwaysActive } = props;
  const containerRef = useRef(null);
  const [isNavHover, setIsNavHover] = useState(false);
  // 导航栏偏移
  const [isNavLeft, setIsNavLeft] = useState(0);
  const [isWindowScrollAtTop, setIsWindowScrollAtTop] = useState(true);
  const [currentActiveMenu, setCurrentActiveMenu] = useState('');
  // const [hasLoggedin, setHasLoggedin] = useState(false);
  const [technologyCapabilityItem, setTechnologyCapabilityItem] = useState(
    navigatorData.devAndServiceData.list[0].key
  );
  const [aiItem, setAiItem] = useState(
    navigatorData.aiClass.list[0].children[0].key
  );
  const [inputValue, setInputValue] = useState([]); // 指定当前选中的条目
  const [abilitySearchData, setAbilitySearchData] = useState([]); // 用来存放开能能力三级菜单数据（模糊搜索）
  const scrollChange = () => {
    // 监听div内滚动条距离左部距离
    setIsNavLeft(-document.documentElement.scrollLeft);
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
    // 滚动条滚动时触发
    if (currentActiveMenu !== '') {
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('scroll', scrollChange, true);
    scrollChange();
    return () => {
      window.removeEventListener('scroll', scrollChange, false);
    };
  }, [currentActiveMenu]);

  // 开放能力改变二级菜单
  const changeTechnologySecond = useCallback((path) => {
    setTechnologyCapabilityItem(path);
    let technologySecondDom = document.getElementById(
      'technologyCapabilityContent'
    );
    technologySecondDom.scrollTop = 0;
  }, []);
  // AI改变二级菜单
  const changeAiSecond = useCallback((path) => {
    setAiItem(path);
  }, []);
  const logout = useCallback(() => {
    account.consoleLogout().then(() => {
      // setHasLoggedin(false);
      props.logoutFunc();
    });
  }, []);

  const handleDownloadBtnClick = useCallback(() => {
    let origin = window.location.origin;
    window.location.href = origin + '/downloadCenter';
  }, []);

  const handleConsoleBtnClick = useCallback(() => {
    if (props.hasLoggedin) {
      window.open(paasUrl.current + '/console/manage/homepage');
    } else {
      window.open('/account/login?from=console');
    }
  }, [props.hasLoggedin]);

  const setNavHover = useCallback(() => {
    setIsNavHover(true);
  }, []);

  const setNavNotHover = useCallback(() => {
    setIsNavHover(false);
    setCurrentActiveMenu('');
  }, []);

  const checkWindowScrollAtTop = () => {
    if (window.scrollY > 0 && isWindowScrollAtTop === true) {
      setIsWindowScrollAtTop(false);
    } else if (window.scrollY === 0 && isWindowScrollAtTop === true) {
      setIsWindowScrollAtTop(true);
    }
  };

  // const checkHasLogin = useCallback(() => {
  //   account.getUserDetail().then(
  //     (res) => {
  //       let bool = '';
  //       if (res.code === 2000000) {
  //         bool = true;
  //       } else {
  //         bool = false;
  //       }
  //       setHasLoggedin(bool);
  //     },
  //     (err) => {
  //       setHasLoggedin(false);
  //     }
  //   );
  // }, []);

  const componentDidMount = useCallback(() => {
    checkWindowScrollAtTop();
    window.addEventListener('scroll', checkWindowScrollAtTop);
    // checkHasLogin();
    window.addEventListener('focus', () => {
      // checkHasLogin();
    });
  }, [checkWindowScrollAtTop]);

  useEffect(() => {
    componentDidMount();
  }, [componentDidMount]);

  // 三级标题的icon组件
  const thirdLabelIcon = useCallback((key) => {
    return (
      <>
        {typeof key !== 'undefined' && (
          <img
            className={less.icon}
            src={`../../static/navigator/${TAG_ZH_TO_EN[key]}.svg`}
            width="30px"
            height="14px"
          />
        )}
      </>
    );
  }, []);
  // 打开开放能力菜单 并且高亮第一个一级菜单
  const openTechnologyCapability = useCallback(() => {
    setCurrentActiveMenu('technologyCapability');
    // setTechnologyCapabilityItem(navigatorData.ability.list[0].key);
  }, []);

  // 开发与生态
  const getSecondList = useCallback(
    (childKey) => {
      const childData = navigatorData.devAndServiceData.list.find(
        (item) => item.key === childKey
      );
      let secondDomList = childData.children.map((item, index) => {
        return (
          <div
            className={classnames(
              less.secondMenuItemArea,
              less.aiSecondMenuItemArea,
              { [less.aiCourse]: childKey === 'AI学堂' }
            )}
            key={`${item.key}-${index}`}
          >
            <div
              className={classnames(less.hasScondMenuTitleCommon, {
                [less.noSubTitleBox]: !item.label
              })}
            >
              {item.label && (
                <span className={less.secondMenuTitleBox}>
                  {SECOND_TITLE_TO_ICON[item.label] && (
                    <CustomIcon
                      type={SECOND_TITLE_TO_ICON[item.label]}
                      classnames={less.secondMenuTitleIcon}
                    />
                  )}
                  {item.label}
                </span>
              )}
              <div className={less.titleContent}>
                {item.children.map((itemChild, idx) => {
                  return (
                    <div
                      className={less.titleItem}
                      key={`${itemChild.key}-${idx}`}
                    >
                      <span className={less.thirdLabel}>
                        <b
                          onClick={() =>
                            gotoDevAndService(itemChild.url, item.key)
                          }
                          className={less.labelHover}
                        >
                          {itemChild.label}
                        </b>
                        {thirdLabelIcon(itemChild.tag)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      });
      return secondDomList;
    },
    [gotoProductPage, thirdLabelIcon]
  );

  // 打开AI菜单 并且初始化三级菜单
  const openAiSecond = useCallback(() => {
    setCurrentActiveMenu('ai-school');
    setAiItem(navigatorData.aiClass.list[0].children[0].key);
  }, []);

  const openDevAndService = useCallback(() => {
    setCurrentActiveMenu('devAndService');
    setTechnologyCapabilityItem(navigatorData.devAndServiceData.list[0].key);
  }, []);

  // AI三级
  const getAiSecondList = useCallback(
    (childKey) => {
      const childData = navigatorData.aiClass.list[0].children.find(
        (item) => item.key === childKey
      );
      let secondDomList = childData.children.map((item, index) => {
        return (
          <div
            className={classnames(
              less.secondMenuItemArea,
              less.aiSecondMenuItemArea
            )}
            key={`${item.key}-${index}`}
          >
            <>
              <div className={less.secondMenuTitle}>
                <span
                  className={less.thirdTitle}
                  onClick={() => gotoAISchool(childData.key, item.url)}
                >
                  <b className={less.thirdLabel}>{item.label}</b>
                </span>
              </div>
            </>
          </div>
        );
      });
      return secondDomList;
    },
    [gotoAISchool]
  );

  // 开放能力展示列表
  const abilityCaseDataNavList = useMemo(() => {
    let sourceData = navigatorData.ability;
    const { list, firstTitleList } = sourceData;
    const defualtTitle = firstTitleList[0].key;
    const currentCaseShowDataList = list.find(
      (item) => item.key === defualtTitle
    ).children;
    return currentCaseShowDataList.map((item, index) => {
      return (
        <div
          className={`${less.caseContent} ${
            technologyCapabilityItem === item.key && less.active
          }`}
          key={`${item.key}-${index}`}
        >
          <div className={less.hasScondMenuTitleCommon}>
            <span className={less.secondMenuTitleBox}>
              {SECOND_TITLE_TO_ICON[item.label] && (
                <CustomIcon
                  type={SECOND_TITLE_TO_ICON[item.label]}
                  classnames={less.secondMenuTitleIcon}
                />
              )}
              {item.label}
            </span>
            <div className={less.titleContent}>
              {item.children.map((itemChild, idx) => {
                return (
                  <div
                    className={less.titleItem}
                    key={`${itemChild.key}-${idx}`}
                  >
                    <span className={less.thirdLabel}>
                      <b
                        onClick={() => gotoProductPage(itemChild.url)}
                        className={less.labelHover}
                      >
                        {itemChild.label}
                      </b>
                      {thirdLabelIcon(itemChild.tag)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  }, [gotoSolutionPage, technologyCapabilityItem, thirdLabelIcon]);

  // 解决方案展示列表
  const caseDataNavList = useMemo(() => {
    let sourceData = navigatorData.case;
    const { list, firstTitleList } = sourceData;
    const defualtTitle = firstTitleList[0].key;
    const currentCaseShowDataList = list.find(
      (item) => item.key === defualtTitle
    ).children;
    return currentCaseShowDataList.map((item, index) => {
      return (
        <div
          className={`${less.caseContent} ${
            technologyCapabilityItem === item.key && less.active
          }`}
          key={`${item.key}-${index}`}
        >
          <div className={less.hasScondMenuTitleCommon}>
            <span className={less.secondMenuTitleBox}>
              {SECOND_TITLE_TO_ICON[item.label] && (
                <CustomIcon
                  type={SECOND_TITLE_TO_ICON[item.label]}
                  classnames={less.secondMenuTitleIcon}
                />
              )}
              {item.label}
            </span>
            <div className={less.titleContent}>
              {item.children.map((itemChild, idx) => {
                return (
                  <div
                    className={less.titleItem}
                    key={`${itemChild.key}-${idx}`}
                  >
                    <span className={less.thirdLabel}>
                      <b
                        onClick={() => gotoSolutionPage(itemChild.url)}
                        className={less.labelHover}
                      >
                        {itemChild.label}
                      </b>
                      {thirdLabelIcon(itemChild.tag)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  }, [gotoSolutionPage, technologyCapabilityItem, thirdLabelIcon]);

  // 服务与支持展示列表
  const serviceDataNavList = useMemo(() => {
    let sourceData = navigatorData.support;
    const { list, firstTitleList } = sourceData;
    const defualtTitle = firstTitleList[0].key;
    const currentCaseShowDataList = list.find(
      (item) => item.key === defualtTitle
    ).children;
    return currentCaseShowDataList.map((item, index) => {
      return (
        <div
          className={`${less.caseContent} ${
            technologyCapabilityItem === item.key && less.active
          }`}
          key={`${item.key}-${index}`}
        >
          <div className={less.hasScondMenuTitleCommon}>
            <span className={less.secondMenuTitleBox}>
              {SECOND_TITLE_TO_ICON[item.label] && (
                <CustomIcon
                  type={SECOND_TITLE_TO_ICON[item.label]}
                  classnames={less.secondMenuTitleIcon}
                />
              )}
              {item.label}
            </span>
            <div className={less.titleContent}>
              {item.children.map((itemChild, idx) => {
                return (
                  <div
                    className={less.titleItem}
                    key={`${itemChild.key}-${idx}`}
                  >
                    <span className={less.thirdLabel}>
                      <b
                        onClick={() => {
                          gotoSevircePage(itemChild.url);
                          gotoPass(itemChild);
                        }}
                        className={less.labelHover}
                      >
                        {itemChild.label}
                      </b>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  }, [gotoPass, gotoSevircePage, technologyCapabilityItem]);

  // 文本框值变化时回调
  const searchItem = (value) => {
    if (value == '') {
      setAbilitySearchData([]);
    } else {
      let res = initAbilityData.filter((item) => {
        return item.label.indexOf(value) >= 0;
      });
      setAbilitySearchData(res);
    }
  };
  const handleChange = (value) => {
    setInputValue(value);
    setAbilitySearchData([]);
  };
  // 失去焦点时
  const loseBlur = () => {
    setAbilitySearchData([]);
  };
  const getPopupContainer = useCallback(() => {
    return document.getElementById('container');
  });
  return (
    <div
      ref={containerRef}
      id="container"
      className={`${less.container} ${
        isNavigatorAlwaysActive || isNavHover || !isWindowScrollAtTop
          ? less.active
          : ''
      }`}
      style={{ left: isNavLeft }}
      onMouseLeave={setNavNotHover}
    >
      <div className={less.leftPart} onMouseEnter={setNavHover}>
        <div className={less.logoBox} onClick={gotoHomePage}>
          <img
            className={less.logo}
            src="https://openplantform.oss-cn-beijing.aliyuncs.com/logo/logo%403x-1-navigator.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=4739852613&Signature=fkQuH6jrDNm4QnqqTwHmW4%2FGgjI%3D"
            alt="tal logo"
          />
        </div>
        <div className={less.menu}>
          <Dropdown
            className={less.customNavDropdown}
            overlayClassName={less.customNavDropdownOverlay}
            getPopupContainer={() => containerRef?.current}
            placement="bottomCenter"
            visible={currentActiveMenu === 'mathGPT'}
            transitionName=""
            // visible
            overlay={
              <div className={less.gptNavList}>
                <span className={less.gptNavItem}>
                  <a href="/solution?name=problem_solving">解题解决方案</a>
                </span>
                <span className={less.gptNavItem}>
                  <a href="/solution?name=correct_solution">批改解决方案</a>
                </span>
              </div>
            }
          >
            <div
              className={`${less.menuItem} ${
                currentActiveMenu === 'mathGPT' && less.active
              }`}
              onMouseEnter={() => {
                setCurrentActiveMenu('mathGPT');
              }}
            >
              九章大模型
            </div>
          </Dropdown>

          <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'technologyCapability' && less.active
            }`}
            onMouseEnter={openTechnologyCapability}
          >
            开放能力
          </div>
          <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'solutions' && less.active
            }`}
            onMouseEnter={() => {
              setCurrentActiveMenu('solutions');
            }}
          >
            解决方案
          </div>
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'dataset' && less.active
            }`}
            onMouseEnter={() => {
              setCurrentActiveMenu('dataset');
            }}
            // onClick={() => (window.location.href = '/dataset')}
          >
            开放数据
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'ai-university' && less.active
            }`}
            onMouseEnter={() => {
              setCurrentActiveMenu('ai-university');
            }}
            onClick={() => window.open(ai_university_url.current)}
          >
            AI题库
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'ai-school' && less.active
            }`}
            onMouseEnter={openAiSecond}
          >
            AI学堂
          </div> */}
          {/* <div
            className={`${less.menuItem} ${currentActiveMenu === 'ecological-cooperation'
            && less.active
              }`}
            onMouseEnter={() =>
              {setCurrentActiveMenu('ecological-cooperation'})
            }
          >
            生态合作
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'platformHonors' && less.active
            }`}
            onMouseEnter={() => {
              setCurrentActiveMenu('platformHonors');
            }}
          >
            平台荣誉
          </div> */}
          <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'service-support' && less.active
            }`}
            onMouseEnter={() => setCurrentActiveMenu('service-support')}
          >
            服务与支持
          </div>
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'match-info' && less.active
            }`}
            onMouseEnter={() => {
              setCurrentActiveMenu('match-info');
            }}
          >
            AI大赛
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'new-activity' && less.active
            }`}
            onMouseEnter={() => setCurrentActiveMenu('new-activity')}
            // onClick={() =>
            //   window.open(
            //     'https://openai.100tal.com/documents/article/page?fromWhichSys=console&id=27'
            //   )
            // }
          >
            最新活动
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'union-active' && less.active
            }`}
            onMouseEnter={() => setCurrentActiveMenu('union-active')}
            // onClick={() =>
            //   window.open(
            //     'https://openai.100tal.com/documents/article/page?fromWhichSys=console&id=27'
            //   )
            // }
            onClick={() => gotoInfoPage('/industrialUnion/union')}
          >
            产业联盟
          </div> */}
          {/* <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'develop-service' && less.active
            }`}
            onMouseEnter={() => setCurrentActiveMenu('develop-service')}
            onClick={() => gotoInfoPage('/developService/market')}
          >
            AI市场
          </div> */}
          <div
            className={`${less.menuItem} ${
              currentActiveMenu === 'devAndService' && less.active
            }`}
            onMouseEnter={openDevAndService}
          >
            开发与生态
          </div>
          <Dropdown
            className={less.customNavDropdown}
            overlayClassName={classnames(
              less.customNavDropdownOverlay,
              less.platformHonorOverlay
            )}
            getPopupContainer={() => containerRef?.current}
            placement="bottomCenter"
            visible={currentActiveMenu === 'platformHonor'}
            transitionName=""
            // visible
            overlay={
              <div className={less.platformHonorList}>
                <span className={less.platformHonorItem}>
                  <a href="/platformHonors/newsInfo">新闻资讯</a>
                </span>
                <span className={less.platformHonorItem}>
                  <a href="/platformHonors/paper">论&nbsp;&nbsp;文</a>
                </span>
                <span className={less.platformHonorItem}>
                  <a href="/platformHonors/model">开源模型</a>
                </span>
                <span className={less.platformHonorItem}>
                  <a href="/platformHonors/softWare">软&nbsp;&nbsp;著</a>
                </span>
                <span className={less.platformHonorItem}>
                  <a href="/platformHonors/race">比赛专利</a>
                </span>
              </div>
            }
          >
            <div
              className={`${less.menuItem} ${
                currentActiveMenu === 'platformHonor' && less.active
              }`}
              onMouseEnter={() => {
                setCurrentActiveMenu('platformHonor');
              }}
            >
              平台荣誉
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={less.userInfoBox} onMouseEnter={setNavHover}>
        {/* <div className={less.dowlandBtn} onClick={handleDownloadBtnClick}>
          下载中心
        </div> */}
        <div className={less.consoleBtn} onClick={handleConsoleBtnClick}>
          控制台
        </div>
        {props.hasLoggedin === false && (
          <div className={less.loginBox}>
            <a className={less.loginBtn} href="/account/login">
              登录
            </a>
            <span className={less.loginLineRegister}>/</span>
            <a
              className={less.registerBtn}
              href="/account/register"
              target="_blank"
            >
              注册
            </a>
          </div>
        )}
        {props.hasLoggedin && (
          <div className={less.userInfo}>
            <div className={less.username}>
              {account.getUserInfo().username}{' '}
              <span className={less.downIcon}> ⌵</span>
            </div>
            <div className={less.funcBox}>
              <div
                className={less.func}
                onClick={() =>
                  window.open(paasUrl.current + '/console/manage/homepage')
                }
              >
                个人中心
              </div>
              <div className={less.func} onClick={logout}>
                退出
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`${less.subMenuBox} ${currentActiveMenu ? less.active : ''}`}
      >
        {currentActiveMenu === 'devAndService' && (
          <>
            {/* <div style={{ backgroundColor: '#212121', width: '100%' }}>
              <div className={`${less.searchBox} ${less.aceViewCenter}`}>
                <div className={`${less.abilitySearch} ${less.blockLeft}`}>
                  <Select
                    mode="multiple"
                    labelInValue
                    value={inputValue}
                    placeholder="搜索开放能力名称"
                    notFoundContent={null}
                    onBlur={loseBlur}
                    defaultActiveFirstOption={false}
                    dropdownMatchSelectWidth={false}
                    showArrow
                    suffixIcon={<Icon type="search" />}
                    filterOption={false}
                    onSearch={searchItem}
                    onChange={handleChange}
                    getPopupContainer={getPopupContainer}
                    style={{ width: '100%' }}
                  >
                    {abilitySearchData.map((d) => (
                      <Option
                        key={d.key}
                        onClick={() => gotoProductPage(d.url)}
                      >
                        {d.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div> */}
            <div
              className={`${less.technologyCapabilitySubMenu} ${less.aceViewCenter}`}
            >
              <div
                className={`${less.technologyCapabilityTitle} ${less.blockLeft}`}
              >
                <>
                  {navigatorData.devAndServiceData.firstTitleList.map(
                    (item, index) => {
                      return (
                        <div
                          className={`${less.titleContent} ${
                            technologyCapabilityItem === item.key && less.active
                          }`}
                          key={`${item.key}-${index}`}
                        >
                          <span
                            className={less.secondTitle}
                            onMouseEnter={() =>
                              changeTechnologySecond(item.key)
                            }
                          >
                            {item.label}
                          </span>
                        </div>
                      );
                    }
                  )}
                </>
              </div>
              <div
                className={less.technologyCapabilityContent}
                id={'technologyCapabilityContent'}
              >
                <div className={less.menuContainer}>
                  {getSecondList(technologyCapabilityItem)}
                </div>
              </div>
            </div>
          </>
        )}
        {currentActiveMenu === 'technologyCapability' && (
          <div className={`${less.solutionsSubMenu} ${less.aceViewCenter}`}>
            {/* 开放能力下拉菜单 */}
            {abilityCaseDataNavList}
          </div>
        )}
        {currentActiveMenu === 'solutions' && (
          <div className={`${less.solutionsSubMenu} ${less.aceViewCenter}`}>
            {/* 解决方案下拉菜单 */}
            {caseDataNavList}
          </div>
        )}
        {/* {currentActiveMenu === 'platformHonors' && (
          <div
            className={`${less.noSecondTitleCommonMenu} ${less.aceViewCenter}`}
          >
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/newsInfo')}
              >
                新闻资讯
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/model')}
              >
                开源模型
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/race')}
              >
                比赛奖项
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/patent')}
              >
                专利
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/paper')}
              >
                论文
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/platformHonors/softWare')}
              >
                软著
              </div>
            </div>
          </div>
        )} */}
        {currentActiveMenu === 'ai-school' && (
          <div className={`${less.aiSubMenu} ${less.aceViewCenter}`}>
            <div className={`${less.aiTitle} ${less.blockLeft}`}>
              {/* 遍历一级标题 */}
              <>
                {navigatorData.aiClass.list[0].children.map((item, index) => {
                  return (
                    <div
                      className={`${less.titleContent} ${
                        aiItem === item.key && less.active
                      }`}
                      key={`${item.key}-${index}`}
                    >
                      <span
                        className={less.secondTitle}
                        onMouseEnter={() => changeAiSecond(item.key)}
                      >
                        {item.label}
                        <b className={less.titleIcon}>{'>'}</b>
                      </span>
                    </div>
                  );
                })}
              </>
            </div>
            <div className={less.aiContent}>{getAiSecondList(aiItem)}</div>
          </div>
        )}
        {currentActiveMenu === 'ecological-cooperation' && (
          <div
            className={`${less.noSecondTitleCommonMenu} ${less.aceViewCenter}`}
          >
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => window.open('https://openi.pcl.ac.cn/TAL')}
              >
                AI协作平台
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => (window.location.href = '/partner')}
              >
                合作伙伴
              </div>
            </div>
          </div>
        )}
        {currentActiveMenu === 'match-info' && (
          <div
            className={`${less.noSecondTitleCommonMenu} ${less.aceViewCenter}`}
          >
            <div className={less.subMenuType}>
              <div
                className={less.title}
                style={{ marginBottom: '10px' }}
                onClick={() => gotoInfoPage('/icdar')}
              >
                ICDAR 2023
              </div>
              <div className={less.title} onClick={() => gotoInfoPage('/aaai')}>
                AAAI 2023
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                style={{ marginBottom: '10px' }}
                onClick={() => gotoInfoPage('/iscslp')}
              >
                ISCSLP 2022
              </div>
              <div className={less.title} onClick={() => gotoInfoPage('/magi')}>
                MAGICHUB 2022
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                style={{ marginBottom: '10px' }}
                onClick={() => gotoInfoPage('/race/cvpr2021')}
              >
                CVPR 2021
              </div>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/race/cvpr2022')}
              >
                CVPR 2022
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                style={{ marginBottom: '10px' }}
                onClick={() => gotoInfoPage('/race/tableIdentify')}
              >
                表格识别 2021
              </div>
            </div>
          </div>
        )}
        {currentActiveMenu === 'new-activity' && (
          <div
            className={`${less.noSecondTitleCommonMenu} ${less.aceViewCenter}`}
          >
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/activity/study_fucture')}
              >
                人工智能与未来学习
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/activity/children')}
              >
                人工智能为儿童
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/activity/articial')}
              >
                面向儿童的人工智能北京共识
              </div>
            </div>
            {/* <div className={less.subMenuType}>
              <div className={less.title}>“教育+AI”培训及宣讲会</div>
            </div> */}
          </div>
        )}
        {currentActiveMenu === 'service-support' && (
          <div className={`${less.serviceSubMenu} ${less.aceViewCenter}`}>
            {/* 服务与支持下拉菜单 */}
            {serviceDataNavList}
          </div>
        )}
        {currentActiveMenu === 'dataset' && (
          <div
            className={`${less.noSecondTitleCommonMenu} ${less.aceViewCenter}`}
          >
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/openData/ocr')}
              >
                OCR
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/dataset')}
              >
                公式识别
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/openData/picture')}
              >
                图像
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/openData/nlp')}
              >
                NLP
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/openData/voice')}
              >
                语音
              </div>
            </div>
            <div className={less.subMenuType}>
              <div
                className={less.title}
                onClick={() => gotoInfoPage('/openData/knowledgeGraph')}
              >
                知识图谱
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Navigator);
